export enum FeatureAccess {
  Off,
  Partial,
  Full,
  InheritSku,
}

export interface FeatureAccessSetting {
  id: string;
  fAccess: FeatureAccess;
}

export enum IndexSetting {
  Off = 1,
  On = 2,
  RaceOff = 3,
}

export enum IntegrationType {
  Rediscovery = 'rediscovery',
  Export = 'export',
  Inbound = 'inbound',
  Analytics = 'analytics',
}

export enum RediscoveryType {
  ConnectOnly = 'ConnectOnly',
  Full = 'Full',
}

export enum ValidationStatus {
  Unverified = 'unverified',
  Validated = 'validated',
  PermissionError = 'permissionerror',
  InternalTestFailure = 'internaltestfailure',
}

export enum PermissionValidationStatus {
  Success = 'success',
  Failure = 'failure',
  Untested = 'untested',
}

export interface PermissionInfo {
  id: string;
  name: string;
  description: string;
  status?: PermissionValidationStatus;
  errorInfo?: PermissionErrorInfo;
  required?: boolean;
}

export enum ATSType {
  Avature = 'avature',
  Avionte = 'avionte',
  Ashby = 'ashby',
  Ascendify = 'ascendify',
  Beamery = 'beamery',
  Beamery2 = 'beamery2',
  BeameryOAuth = 'beameryoauth',
  Bullhorn = 'bullhorn',
  Cats = 'cats',
  Crelate = 'crelate',
  GR8People = 'gr8people',
  Gem = 'gem',
  Greenhouse = 'greenhouse',
  GreenhouseIngestion = 'greenhouse_ingestion',
  iCIMS = 'icims',
  iCIMSPublic = 'icims_public', // icims and icims_public will eventually merge.
  iCIMSStream = 'icims_stream',
  Invenias = 'invenias',
  IQRecruit = 'iqrecruit',
  Jobvite = 'jobvite',
  JobDiva = 'jobdiva',
  Lever = 'lever',
  LeverOpportunities = 'lever_opportunities',
  LeverOAuth = 'leveroauth',
  Loxo = 'loxo',
  Oleeo = 'oleeo',
  PhenomPeople = 'phenom_people',
  Recruiterflow = 'recruiterflow',
  SmartRecruiters = 'smart_recruiters',
  SAPSuccessFactors = 'sap_successfactors',
  Taleo = 'taleo',
  Talemetry = 'talemetry',
  Thrive = 'thrive',
  TrackTalents = 'tracktalents',
  Workable = 'workable',
  Workday = 'workday',
  SalesForce = 'salesforce',
  SourceWhale = 'sourcewhale',
  Smashfly = 'smashfly',
  HeyMarket = 'heymarket',
  JobScore = 'jobscore',
  JazzHR = 'jazzhr',
  Clinch = 'clinch',
  CustomReport = 'custom_report',
}

export enum Feature {
  // search verticals
  Search = 'feature_search',
  PublicProfiles = 'feature_public_profiles',
  InternalTalent = 'feature_eto',
  Healthcare = 'feature_healthcare',
  GitHub = 'feature_github',
  Expert = 'feature_expert',
  PeopleInsights = 'feature_analytics',
  OtherNetworks = 'feature_other_networks',

  JobPermissions = 'feature_job_permissions',
  Inbound = 'feature_inbound',
  Impersonate = 'feature_impersonate',
  InternaltalentNewView = 'feature_internaltalent_new_view',
  ChatGPTSearchMessage = 'feature_chatgpt_search_message',
  DiversityFilters = 'feature_diversity_filters',
  CustomPowerFilters = 'feature_custom_power_filters',
  CountryOrigin = 'feature_country_origin',
  AIMatching = 'feature_ai_matching',
  LikelyToMove = 'feature_likely_to_move',
  EnterpriseProjectVisibility = 'feature_enterprise_project_visibility',
  EnterpriseSku = 'feature_enterprise_sku',
  ExportableInsights = 'feature_exportable_insights',
  CustomInsights = 'feature_custom_insights',
  StartupPowerFilters = 'feature_startup_power_filters',
  LadderLevelsPowerFilters = 'feature_ladder_levels_power_filters',
  AlumniPowerFilters = 'feature_alumni_power_filters',
  EnhancedSecurityClearancePowerFilters = 'feature_enhanced_security_clearance_power_filters',
  SelfServeCustomTagsStatuses = 'feature_self_serve_custom_tags_statuses',
  OrgWideProjectSharing = 'feature_org_wide_project_sharing',
  SharedMessagingTemplates = 'feature_shared_messaging_templates',
  Messaging = 'feature_messaging',
  PipelineInsights = 'feature_analytics2',
  IntegrationExport = 'feature_integration_export',
  IntegrationConnect = 'feature_integration_connect',
  IntegrationFullRediscovery = 'feature_integration_full_rediscovery',
  Labs = 'feature_labs',
  Hotjar = 'feature_hotjar',
  DiversityBoost = 'feature_diversity_boost',
  WhitelistMode = 'feature_whitelist_mode',
  Sandesh = 'feature_sandesh',

  InboundAdmin = 'feature_inbound_admin',
  PremiumGPT = 'feature_premiumgpt',
  SmartMatch = 'feature_smart_match',
  SSO = 'feature_sso',
  CandidateOutreach = 'feature_project_outreach',
  BiasReducer = 'feature_bias_reducer',
  SharedNotes = 'feature_shared_notes',
  FilterByDiversityInsights = 'feature_filter_by_diversity_insights',
  BulkExportToPDF = 'feature_bulk_export_to_pdf',
  PeopleInsightsExport = 'feature_people_insights_export',
  CandidateOutreachMultipleAccounts = 'feature_candidate_outreach_multiple_accounts',
  GitHubExpertProfileInfo = 'feature_github_expert_profile_info',
  BuyAddonCredits = 'feature_buy_addon_credits',
  ConversationalSearch = 'feature_conversational_search',
  SearchLanding = 'feature_search_landing',
  SearchInsights = 'feature_search_insights',
  AlumniSearch = 'feature_alumni_search',
  SendOnBehalfNoOutreach = 'feature_send_on_behalf_no_outreach',
  InternalTalentDemoMode = 'feature_internal_talent_demo_mode',
  ApplicantReviewDemoUpsell = 'feature_applicant_review_demo_upsell',
  TalentRediscoveryDemoUpsell = 'feature_talent_rediscovery_demo_upsell',
  PipelineInsightsDemoUpsell = 'feature_pipelinet_insights_demo_upsell',
}

export enum ContactsRequestState {
  New = 'New',
  Existing = 'Existing',
  Polling = 'Polling',
  ExistingAndNotStale = 'ExistingAndNotStale',
}

export enum CoachMarkActivity {
  AddFilter = 'addFilter',
  AddToProject = 'addToProject',
  ExportCandidates = 'exportCandidates',
  GetContactInfo = 'getContactInfo',
  ShareProject = 'shareProject',
  SmartMessage = 'smartMessage',
  MultiSearch = 'multiSearch',
}
